import React from "react";
import { Box, Button, TextField, Typography, Stack, Alert, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setLoginFetchingError, setLoginFetching } from "state/index"; // Update this path accordingly
import { useLogInMutation } from "state/api";
import { useNavigate } from 'react-router-dom';
import { FormContainer, TextFieldElement, useWatch } from 'react-hook-form-mui'
import { useTranslation } from 'react-i18next';

const SubComponent = () => {
  const { t } = useTranslation()
  const [email, password] = useWatch({
    name: ['email', 'password'],
  })
  const error = useSelector((state) => state.global.login.error);
  const fetching = useSelector((state) => state.global.login.fetching);

  return (
    <>
      <Stack spacing={3}>
        <Button
          type={'submit'}
          color={'primary'}
          variant={'contained'}
          disabled={!(email && password && !fetching)}
        >
          {t('login.submit.button')}
        </Button>
        <Alert variant={'outlined'} severity={'info'}>
          {t('login.alert.info')}
        </Alert>
        { error ? <Alert variant={'outlined'} severity={'error'}>
          {t('login.alert.error')}
        </Alert> : <></> }
      </Stack>
    </>
  )
}

const Login = () => {
  const isAuthenticated = useSelector((state) => state.global.auth.isAuthenticated);

  const { t } = useTranslation()
  const dispatch = useDispatch();
  const history = useNavigate();
  const [logIn, result] = useLogInMutation()
  const error = useSelector((state) => state.global.login.error);
  const fetching = useSelector((state) => state.global.login.fetching);
  if (isAuthenticated) {
    history('/')
  }
  const submitForm = async (form) => {
    try {
      if (error) {
        dispatch(setLoginFetchingError())
      }
      if (fetching) {
        return
      }
      dispatch(setLoginFetching())
      const { data } = await logIn(form); 
      dispatch(setLoginFetching())
      if (undefined === data) {
        dispatch(setLoginFetchingError())
        return
      }
      dispatch(setAuth(data));
      history("/") // TODO MAKE RETURN WHERE WE WAS
    } catch (error) {
      // Handle login error (e.g., display an error message)
      console.error('Login failed:', error);
    }
  };
  
  return (
    <Box m="1.5rem 2.5rem">
      <FormContainer autoComplete="off" defaultValues={{ email: '', password: '' }} onSuccess={submitForm}>
        <Typography variant="h4" mb={3}>
          {t('login.title')}
        </Typography>
        <Box>
          <Grid container direction={"column"} spacing={1}>
            <Grid item>
              <TextFieldElement name={'email'} autoComplete="off" label={t('login.email')} required variant="outlined" type={'email'} fullWidth mb={2} />
            </Grid>
            <Grid item>
              <TextFieldElement name={'password'} autoComplete="off" label={t('login.password')} required variant="outlined" type={'password'} fullWidth mb={2} />{' '}
            </Grid>
            <Grid item>
              <SubComponent />
            </Grid>
          </Grid>
        </Box>
      </FormContainer>
    </Box>
  );
};

export default Login;