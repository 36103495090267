import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_BASE_URL ||
      "http://localhost:5001",
      prepareHeaders: (headers, { getState }) => {
        headers.set('Content-Type', 'application/json');
        return headers;
      },
      credentials: 'include'
  }), 
  reducerPath: "api",
  tagTypes: [
    "Info",
    "Logout",
    "Login",
    "User",
    "Producs",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Fakes",
    "List",
    "Edit"
  ],
  // endpoints
  endpoints: (build) => ({
    logIn: build.mutation({
      query: (body) => ({
        url: `api/user/login`,
        method: 'POST',
        body,
      }),
      providesTags: ["User", "Login"],
    }),
    logOut: build.mutation({
      query: () => ({
        url: `api/user/logout`,
        method: 'POST',
        body: {},
      }),
      providesTags: ["User", "Logout"],
    }),
    getUser: build.query({
      query: (id) => ({
        url: `api/user/info/${id}`,
        method: 'GET'
      }),
      providesTags: ["User", "Info"],
    }),
    checkLogIn: build.query({
      query: ()=> ({
        url: `api/user/logged`,
        method: 'POST'
      }),
      providesTags: ["User", "Info"],
    }),
    getFakes: build.query({
      query: ()=> ({
        url: `api/fakes/list`,
        method: 'POST'
      }),
      providesTags: ["Fakes", "List"],
    }),
    editFakes: build.mutation({
      query: (body)=> ({
        url: `api/fakes/edit`,
        method: 'POST',
        body
      }),
      providesTags: ["Fakes", "Edit"],
    }) 
  }),
});

export const {
  useLogInMutation,
  useLogOutMutation,
  useGetUserQuery,
  useCheckLogInQuery,
  useGetFakesQuery,
  useEditFakesMutation
} = api;
