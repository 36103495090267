import React from "react";
import { Box } from "@mui/material";
import { Header } from "components";

// Customers
const PageNotFound = () => {

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="NotFound" subtitle="Not Found" />
        404 NOT FOUND
    </Box>
  );
};

export default PageNotFound;
