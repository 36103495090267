import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  auth: {
    isAuthenticated: false,
    isAdmin: false,
    userId: null,
    userName: "",
    userEmail: ""
  },
  active: "",
  login: {
    fetching: false,
    error: false,
    checked: false
  }
};

// reducers to get redux state data
export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setAuth: (state, action) => {
      const { isAuthenticated, isAdmin, userId, userName, userEmail } = action.payload;
      state.auth.isAuthenticated = isAuthenticated;
      state.auth.isAdmin = isAdmin;
      state.auth.userId = userId;
      state.auth.userName = userName;
      state.auth.userEmail = userEmail;
    },
    logOut: (state) => {
      state.auth.isAuthenticated = false;
      state.auth.isAdmin = false;
      state.auth.userId = null;
    },
    setActive: (state, action) => {
      const { active } = action.payload;
      state.active = active
    },
    setLoginFetching: (state) => {
      state.login.fetching = !state.login.fetching
    },
    setLoginFetchingError: (state) => {
      state.login.error = !state.login.error;
    },
    setCheckedLogin: (state) => {
      state.login.checked = !state.login.checked
    }
  },
});

export const { setMode, setAuth, logOut, setActive, setLoginFetching, setLoginFetchingError, setCheckedLogin } = globalSlice.actions;

export default globalSlice.reducer;
