import React from "react";
import { Box, Typography } from "@mui/material";
import { Header } from "components";
import { useTranslation } from 'react-i18next';

// Customers
const CopyRight = () => {
  const { t } = useTranslation();

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="License (c)" subtitle="actual version" />
      <Typography variant="body1">
        {t('copyright.body1')}
      </Typography>
      <Typography variant="body1">
        {t('copyright.body2')}
      </Typography>
      <ol>
        <Typography component="li" variant="body1">
          {t('copyright.body3')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('copyright.body4')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('copyright.body5')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('copyright.body6')}
        </Typography>
      </ol>
    </Box>
  );
};

export default CopyRight;