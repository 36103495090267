import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux"; 

export const PrivateRoute = ({ layout: Layout, redirectPath = '/login' }) => {
    const user = useSelector((state) => state.global.auth.isAuthenticated);
    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }
    return Layout ? <Layout><Outlet /></Layout> : <Outlet />;
};

export const AdminRoute = ({redirectPath = '/login' }) => {
    const user = useSelector((state) => state.global.auth.isAuthenticated);
    const isAdmin = useSelector((state) => state.global.auth.isAdmin); // Assuming isAdmin indicates admin status
    if (!(user && isAdmin)) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};
