import React from "react";
import { CustomPagination, Header, StyledDataGrid } from "components";
import { Box, Button } from "@mui/material";
import { useGetFakesQuery } from "state/api";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
const PAGE_SIZE = 5;
const MyFakes = function MyFakes() {
  const { data, isLoading } = useGetFakesQuery();
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  const columns = [
      { field: "web", headerName: t('columns.type'), flex: 1 },
      { field: "url", headerName: t('columns.url'), flex: 1 },
      { field: "prefix", headerName: t('columns.prefix'), flex: 1 },
      { field: "status", headerName: t('columns.status'), flex: 1 },
      { field: "__v", headerName: t('columns.v'), flex: 1 },
      {
          field: "actions",
          type: "actions",
          headerName: t('columns.actions'),
          flex: 1,
          getActions: (params) => [
              <GridActionsCellItem
                  icon={<DeleteOutline />}
                  label="Delete"
                  onClick={() => handleDelete(params.id)}
              />,
              <GridActionsCellItem
                  icon={<Edit />}
                  label="Edit"
                  onClick={() => handleEdit(params.id)}
              />,
          ],
      }
  ];
  const handleDelete = (id) => {
      // handle delete logic here
  };

  const handleEdit = (id) => {
      console.log(id)
      // handle edit logic here
  };
  const history = useNavigate();

  const handleAddClick = () => {
    // Здесь вы можете открыть модальное окно для добавления нового "Fake" или перенаправить пользователя на другую страницу
    history("/my-fakes/add-fake");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t('fakes.title')} subtitle={t('fakes.subtitle')} />
        <Button variant="contained" color="primary" onClick={handleAddClick}>
          {t('button.add')}
        </Button>
      </Box>
      <StyledDataGrid
        columns={columns}
        loading={isLoading}
        rows={data || []}
        autoHeight
        getRowId={(row) => row._id}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[PAGE_SIZE]}
        slots={{
          pagination: CustomPagination,
        }}
      />
    </Box>
  );
}
export default MyFakes;