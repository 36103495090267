import React, { useEffect, useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute, AdminRoute } from 'components/PrivateRoute'
import { themeSettings } from "theme";
import { useCheckLogInQuery } from "state/api";
import { setAuth, setCheckedLogin } from 'state/index';

import {
  Layout,
  AdminLayout,
  Dashboard,
  Admin,
  Login,
  MyFakes,
  PageNotFound,
  CopyRight,
  AddFake
} from "scenes";

// App
const App = () => {
  const mode = useSelector((state) => state.global.mode);
  const checkedLogin = useSelector((state) => state.global.login.checked);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const dispatch = useDispatch();
  const { data, isLoading } = useCheckLogInQuery();
  useEffect(() => {
    if (data && data.userId) {
      dispatch(setAuth(data)); 
    }
    if (data) dispatch(setCheckedLogin())
  }, [data]);
  return (checkedLogin &&
    <div className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/dashboard"/>} />
          <Route path="/" element={<PrivateRoute layout={Layout} redirectPath="/login" />} >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="my-fakes/add-fake" element={<AddFake />} />
            <Route path="my-fakes" element={<MyFakes />} />
            <Route path="copyright" element={<CopyRight />} />
          </Route>
          <Route element={<AdminRoute layout={AdminLayout} redirectPath="/" />}>
            <Route path="/admin" element={<Admin />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
};

export default App;
