import React from "react";
import { Box, Typography, Paper, ButtonBase } from "@mui/material";
import { Header } from "components";
import { useTranslation } from 'react-i18next';
import PlanetIcon from '@mui/icons-material/Public';
import AndroidIcon from '@mui/icons-material/Android';
import { useNavigate } from 'react-router-dom';

// Customers
const AddFake = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Create App" subtitle="choose app type" />
      <Box display="flex" justifyContent="center">
        <Paper elevation={3} sx={{mx:1}} onClick={() => navigate('/my-domains')}>
          <ButtonBase>
            <Box p={2} textAlign="center">
              <PlanetIcon fontSize="large" />
              <Typography variant="h6">{t('Web 2.0')}</Typography>
              <Typography variant="body1">{t('Web site hosted on our platform')}</Typography>
            </Box>
          </ButtonBase>
        </Paper>
        <Paper elevation={3} sx={{mx:1}} onClick={() => navigate('/my-fakes/add-fake/telegram')}>
          <ButtonBase>
            <Box p={2} textAlign="center">
              <AndroidIcon fontSize="large" />
              <Typography variant="h6">{t('Telegram')}</Typography>
              <Typography variant="body1">{t('Bot hosted on telegram platform')}</Typography>
            </Box>
          </ButtonBase>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddFake;